@import "../global/global.scss";

$font-size: 35px;
$font-weight: 600;
$sub-font-size: 26px;

.main-container {
    width: 90%;
    position: relative;
    margin: 0 auto;
    max-width: 1280px;
    font-family: 'Nunito', sans-serif;
    
    > .team-container {
        justify-content: left;
        width: 75%;
        max-width: 75%;
        min-width: 75%;
        flex-basis: 75%;
        padding: 0 12px 0 0;

        > h1 {
            font-size: $font-size;
            font-weight: $font-weight;
        }
        > h2 {
            padding-left: 20px;
            font-size: $sub-font-size;
        }
        
        > div {
            > h1 {
                font-size: $font-size;
                font-weight: $font-weight;
            }
            > h2 {
                padding-left: 20px;
                font-size: $sub-font-size;
            }
            > .teamMember-container {
    
                width: 100%;
                background-color: #cccccc;
                border: 1px solid $gray;
                border-radius: 25px;
                margin-bottom: 10px;
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;

                @keyframes rotation {
                    0% { rotate: 0deg; padding: 12.5px 0 7.5px 10px; }
                    100% { rotate: 180deg; padding:15px 10px 0 0;  }
                  }

                  @keyframes returnrot {
                    0% { rotate: -180deg; padding:15px 10px 0 0;  }
                    100% { rotate: 0deg; padding: 12.5px 0 7.5px 10px; }
                  }

                .hidden{ 
                    max-height: 0px !important;
                    transition: max-height 0.7s ease-in;
                }

                .grow{
                    max-height: 200px !important;
                    transition: max-height 1s ease-in;
                }

                .break {
                    width: 100%;
                  }

                i {
                    padding: 12.5px 0 7.5px 10px;
                    font-size: $sub-font-size;
                    color: $gray-d; 
                }

                .rotate-0 {
                    animation: returnrot 1s;
                    padding: 12.5px 0 7.5px 10px;
                }

                .rotate-180 {
                    animation: rotation 1s;
                    rotate: 180deg;
                    padding:15px 10px 0 0; 
                }
    
                h5 {
                    padding: 10px 10px 10px 10px;
                    margin: 0;
                    color: $gray-d;
                    font-size: $sub-font-size;
                }
                > .information-container {
                    display: inline-block;
                    max-height: 0;
                    overflow: hidden;

                    img {
                        margin: 0 5px 5px 5px;
                        float:left;
                        border-radius: 25px;
                        width: inherit;
                        height: 150px;
                    }

                    h3 {
                        font-size: $sub-font-size;
                        color: $gray;
                        margin: 0;
                    }

                    p {
                        font-size: $sub-font-size/2;
                        margin: 0;
                    }
                }
            }
        }
    }
}