@import "../settings/settings.scss";

@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,900&display=swap');
@font-face {
    font-family: 'OptimaNova';
    src: local('OptimaNova'), url(../../../public/fonts/OptimaNovaLTProBold.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@mixin font {
    font-family: 'Prompt', sans-serif;
}

// Always keep scrollbars
html {
    overflow-y: scroll;
}

html,
html > body,
html > body > div#root {
    margin: 0;
    padding: 0;
    @include font;
    font-weight: 500;
    background-color: $white-d;
    font-size: 62.5% // Responsive Font Root
}

@mixin page-plus-top {
    padding: $gap;
    gap: $gap;
}

@mixin page {
    padding: 0 $gap $gap $gap;
    gap: $gap;
}

// For footer
html > body > div#root {
    padding-bottom: 50px;
    position: relative;
    min-height: 100vh;
    box-sizing: border-box;
}


a {
    text-decoration: none;
    color: $white;
}


@mixin card {
    filter: drop-shadow(0px 0px 5px $gray-l);

    background-color: $white;
    border-radius: 13px;


    overflow: hidden;
    padding: 20px;


    h1 {
        margin: 0;
        margin-bottom: 2px;
        color: $black;
        font-size: 19px;
        font-weight: 600;
    }
}


.section-label {
    margin-top: 18px;
    display: flex;
    align-items: center;
    gap: 6px;
  
    color: $gray-d;
    font-size: 11px;
    font-weight: 400;

    margin-bottom: 3px;
}


@mixin rounded-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    border: none;
    color: white;
    padding: 7px 11px;
    text-align: center;
    @include font;
    border-radius: 8px;
    transition-duration: 0.4s;
    cursor: pointer;
    height:50%;
    white-space: nowrap;

    > i {
        margin: 0;
        font-size: clamp(1.4rem, 1.6vw, 1.5rem);
    }
    > h1 {
        margin: 0;
        font-size: clamp(1.4rem, 1.6vw, 1.5rem);
        font-weight: 500;
    }
}

section.modal {
    display: none;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(3px);

    align-items: center;
    justify-content: center;

    > * {
        animation: modal-pop-in 0.2s linear;

        filter: drop-shadow(0px 0px 5px $gray-d);

        border-radius: 20px;
        overflow: hidden;
    }

    button {
        max-width: 300px;
        border: none;
        padding: 15px 40px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 15px;

        &.gray {
            background-color: $white-d;
            color: $gray-d;

            &:hover {
                background-color: $gray-l;
                cursor: pointer;
            }
        }

    }
}

section.modal[visible="true"] {
    display: flex;
}


@keyframes modal-pop-in {
    from{
        transform:scale(0.5);
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    80%{
        transform:scale(1.1)
    }
    to{
        transform:scale(1);
        opacity: 1;
    }
}


section.error {

    width: clamp(10px, 40vw, 700px);

    .red {
        background-color: #ff3e3e;
        color: white;
        width: 100%;
        height: 180px;

        display: flex;
        align-items: center;

        font-size: 50px;
    }

    .text {
        padding: 25px 50px;
        background-color: white;

        text-align: center;

        h1 {
            font-size: 30px;
            margin: 6px;
        }

        h2 {
            font-size: 20px;

            a {
                color: $gray-d;
                text-decoration: underline;
            }
        }

        h3 {
            font-size: 13px;
            margin-top: 45px;
        }
    }
}